<template>
  <v-container fluid>
    <datatable
      :tableHeaders="tableHeaders"
      :tableData="tableData"
      :page.sync="tablePage"
      :pageLimit.sync="tablePageLimit"
      :itemTotal="tableItemTotal"
      :isLoading="tableLoading"
      @options-update="getList()"
      enableExport
      @export-clicked="exportData()"
    ></datatable>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import Datatable from '@/components/Datatable.vue'
import ExcelMixin from '@/mixins/ExcelMixin.vue'

export default {
  name: 'Enquiry',
  mixins: [ExcelMixin],
  components: {
    Datatable,
  },
  data: () => ({
    tableLoading: false,
    exportLoading: false,
    tablePage: 1,
    tablePageLimit: 10,
    tableData: [],
    tableItemTotal: 0,

    // --- static data
    tableHeaders: [
      { text: '提交日期', value: 'create_date', width: 150 },
      { text: '姓名', value: 'name', width: 230 },
      { text: '電話', value: 'phone', width: 150 },
      { text: '內容', value: 'content' },
    ],
  }),
  methods: {
    ...mapActions(['setAlert']),
    getQueries() {
      const query = this.$route.query
      if (this.$validate.regexNumber(query['p']) && parseInt(query['p']) > 0) {
        this.tablePage = parseInt(query['p'])
      }

      if (this.$validate.regexNumber(query['l']) && parseInt(query['l']) > 0) {
        this.tablePageLimit = parseInt(query['l'])
      }
      this.getList(true)
    },

    _getData() {
      return new Promise(async resolve => {
        try {
          let payload = {
            limit: this.tablePageLimit,
            page: this.tablePage - 1,
          }

          const user = this.getCurrentUserData()
          const { data, total } = await this.$Fetcher.GetEnquiries(payload, user.id, user.token)
          this.tableItemTotal = total

          const list = data.map(el => {
            return {
              id: el.id,
              create_date: el.create_date,
              create_date_format: this.$Formatter.displayCreateDate(el.create_date),
              name: el.username,
              phone: el.phone,
              content: el.content,
            }
          })

          resolve(list)
        } catch (err) {
          this.$common.error(err)
          if (err) {
            this.$store.dispatch('toggleSnack', {
              type: 'error',
              message: err,
            })
          }
          resolve([])
        }
      })
    },

    async getList(pageInit = false) {
      if (!pageInit) {
        const newQuery = {
          p: this.$validate.regexNumber(this.tablePage) && parseInt(this.tablePage) > 1 ? this.tablePage : undefined,
          l:
            this.$validate.regexNumber(this.tablePageLimit) && parseInt(this.tablePageLimit) !== 10
              ? this.tablePageLimit
              : undefined,
        }

        const validNewQuery = this.$common.validNewQuery(this.$route.query, newQuery)
        if (validNewQuery) {
          this.$router.replace({ query: newQuery })
        }
      }

      this.tableLoading = true

      this._getData().then(list => {
        this.tableData = list
        this.tableLoading = false
      })
    },

    async exportData() {
      if (this.exportLoading) {
        this.$store.dispatch('toggleSnack', {
          show: true,
          message: 'processing',
        })
        return
      }

      this.exportLoading = true
      this._getData()
        .then(list => {
          try {
            let header = {}
            this.tableHeaders.forEach(item => {
              if (item.value === 'create_date') {
                header['create_date_format'] = item.text
              } else {
                header[item.value] = item.text
              }
            })

            this.exportExcel(header, list, '聯絡我們', `聯絡我們.xlsx`)
          } catch (err) {
            this.$common.error(err)
            this.$store.dispatch('toggleSnack', {
              show: true,
              type: 'error',
              message: '匯出失敗',
            })
          } finally {
            this.exportLoading = false
          }
        })
        .catch(() => {
          this.exportLoading = false
        })
    },
  },

  async mounted() {
    this.getQueries()
  },
}
</script>
